/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import HeaderAbout from "./headerAbout"
import Footer from "./footer"
import "./layout.css"

const LayoutAbout = ({ children }) => {
//   const data = useStaticQuery(graphql`
//     query SiteTitleQuery {
//       site {
//         siteMetadata {
//           title
//         }
//       }
//     }
//   `)

  return (
    <>
      <HeaderAbout siteTitle={"test"} />
      <div className="spacerChild">&nbsp;</div>
        <main>{children}</main>
        <Footer></Footer>
    </>
  )
}

LayoutAbout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutAbout