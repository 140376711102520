import React from "react"
import LayoutAbout from "../components/layoutAbout"
import SEO from "../components/seo"
import { color, fontSize } from '../components/constants';
import styled from 'styled-components'

const SectionGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-gap: 0px;
    margin: 0px 36px 10px;

    @media (max-width: 1024px){
        grid-gap: 0px;
        margin: 0px 36px 0px;
    }

    @media (max-width: 640px){
        grid-template-columns: 1fr ;
        grid-template-rows: 1fr;
        grid-gap: 0px;
        margin: 0px 24px 0px;
    }
`
const CellTitleGroup = styled.div`
    grid-column: 2;
    width: 100%;
    @media (max-width: 640px){
        grid-column: 1;
        grid-row: 2;
        width: 100%;
        margin-top: 0px;
    }
`
const Title = styled.div`
    color: ${color.gray2};
    font-size: ${fontSize[2]};
    font-weight: 200;
    line-height: 1.2;
    padding-bottom: 32px;
    word-wrap:normal; /* YESSSSS */
    @media (max-width: 1024px){
        font-size: ${fontSize[2]};
    }

    @media (max-width: 640px){
        word-spacing: normal;
        font-size: ${fontSize[1]};
    }
`

const SecondPage = () => (
  <LayoutAbout>
    <SEO title="about" />
    <SectionGroup>
      <CellTitleGroup>
          <Title>John cross Neumann is a design leader specializing in customer experience, user interface, and design&nbsp;systems. </Title>
          <Title>With over ten years experience, he has built award-winning products that solve customer problems & meet business needs for a variety of companies and&nbsp;startups. </Title>
          <Title>He has held senior design, creative leadership, and design management rolls at DAT, Scroll, Beme, Intel, TouchCast, Blackberry, and&nbsp;Motorola. </Title>
          <Title>Client projects include Simplifi, Traction Network, Projector Media, Jenie, and&nbsp;Birddogs. </Title>
          </CellTitleGroup>
      </SectionGroup>
  </LayoutAbout>
)

export default SecondPage
